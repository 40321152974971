<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Admin Details</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="search-wrapper">&nbsp;</div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
        <el-select
          v-model="delectval"
            @change="handleAction"
            placeholder="Select"
            clearable
            filterable
          >
            <el-option label="Update" value="update" ></el-option>
            <el-option label="Change Password" value="changePassword" ></el-option>
            <el-option label="Change Transaction Password" value="changeTransactionPassword" ></el-option>
            <el-option label="Renewal Data" value="renewalData" ></el-option>
        </el-select>

        </div>
      </el-col>
    </el-row>

    <div>
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <span style="color: #fff">Personal Details</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>User Name:</p>
                </th>
                <td>
                  <p>{{ viewData.username }}</p>
                </td>
                <th>
                  <p>Name:</p>
                </th>
                <td>
                  <p>{{ viewData.name }}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Email:</p>
                </th>
                <td>
                  <p>{{ viewData.email }}</p>
                </td>
                <th>
                  <p>Phone #:</p>
                </th>
                <td>
                  <p>{{ viewData.phone }}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Allowed User:</p>
                </th>
                <td>
                  <p>{{ viewData.allowedbroker }}</p>
                </td>
                <th>
                  <p>Allowed Client:</p>
                </th>
                <td>
                  <p>{{ viewData.allowedclient }}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Renewal date:</p>
                </th>
                <td>
                  <p>{{ viewData.renewaldate }}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </el-card>
    </div>
    <ChangeEditDialog
      :dialog-visible.sync="dialogVisibleChangeEdit"
      :dialog-type="dialogTypeChangeEdit"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleUpdatePassword($event)"
      @childClose="handleChildClose()"
    />

  </div>
</template>

<script>
import { edit, updateChange } from "@/api/super-admin/admin";
import ChangeEditDialog from "@/views/super-admin/users/components/ChangeEditDialog.vue";

export default {
  name: "PendingOrders",
  components: {
    ChangeEditDialog
  },
  data() {
    return {
      loading: false,
      viewData: {},
      delectval: '',
      dialogVisibleChangeEdit: false,
      dialogTypeChangeEdit: "create",
      formData: {},
      formErrors: [],
    };
  },
  computed: {},
  created() {
    this.id = this.$route.params.id;
    console.log(this.$route.params.id);
    this.getPendingOrderDetails();
  },
  methods: {
    handleAction(value) {
       console.log(value)
       if(value == 'update') {
        this.handleEdit();
       } else if(value == 'renewalData') {
        this.renewalData()
       } else if (value == 'changeTransactionPassword') {
        this.changeTransactionPassword()
       } else if (value == 'changePassword') {
        this.changePassword()
       }
    },
    handleEdit() {
        return this.$router.push(
          "/super-admin/user-edit/" + this.$route.params.id
        );
    },
    changePassword() {
      this.formData = {};
      this.formErrors = [];
      this.dialogVisibleChangeEdit = true;
      this.dialogTypeChangeEdit = "changePassword";
      this.delectval = null;
    },
    changeTransactionPassword() {
      this.formData = {};
      this.formErrors = [];
      this.dialogVisibleChangeEdit = true;
      this.dialogTypeChangeEdit = "changeTransactionPassword";
      this.delectval = null;
    },
    renewalData() {
      this.formData = {};
      this.formErrors = [];
      this.dialogVisibleChangeEdit = true;
      this.dialogTypeChangeEdit = "changeRenewalData";
      this.delectval = null;
    },
    handleUpdatePassword(data) {
      let dataPost = {
        id : this.$route.params.id, 
        new_password : data.newPassword,
        new_transaction_password : data.newTransactionPassword,
        new_renewal_date : data.newRenewalDate,
      }
      console.log(dataPost);
      updateChange(dataPost)
        .then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.loading = false;
            this.dialogVisiblePasswordEdit = false;
            location.reload()
          } else {
            this.formErrors = this.validationErrors(
              response.data.message
            );
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });

    },
    handleChildClose() {
      this.dialogVisibleChangeEdit = false;
      this.delectval = null;
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getPendingOrderDetails();
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getPendingOrderDetails();
    },
    getPendingOrderDetails() {
      this.loading = true;
      edit(this.$route.params.id).then((response) => {
        this.viewData = response.data.data;
        console.log(this.viewData);
        console.log(this.config);
        this.loading = false;
      });
    },
  },
};
</script>
