<template>
<div v-loading="loading">
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :before-close="handleCloseDialog" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form :model="formData" :rules="formRules" ref="refForm" label-position="top" :status-icon="true">
            <el-form-item v-if="this.dialogType == 'changePassword'" :error="formErrors.newPassword" label="Insert New Password" prop="newPassword" :label-width="formLabelWidth">
                <el-input v-model="formData.newPassword" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item v-if="this.dialogType == 'changeTransactionPassword'" :error="formErrors.newPassword" label="Insert New Transaction Password" prop="newTransactionPassword" :label-width="formLabelWidth">
                <el-input v-model="formData.newTransactionPassword" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item v-if="this.dialogType == 'changeRenewalData'"
                  :error="formErrors.newRenewalDate"
                  label="New Renewal date"
                  prop="newRenewalDate"
                  :label-width="formLabelWidth"
                >
                  <br>
                  <DatePicker
                    :selectedDate.sync="formData.newRenewalDate"
                    :dialogType="dialogType"
                    :min-date="today()"
                    @dateUpdated="handleDateUpdate"
                  />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCloseDialog()">
          Cancel
        </el-button>
            <el-button type="primary" @click="sendChildFormData()">Save</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>

import DatePicker from "@/components/DatePicker/DatePicker.vue";
import { requiredRule } from "@/rules/all-rules";

export default {
    name: "AddEditDialog",
    components: {
        DatePicker
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        },
        dialogType: {
            type: String,
            default: "",
        },
        formData: {
            type: Object,
            default: function () {
                return {};
            },
        },
        formErrors: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {
            loading: false,
            formRules: {},
            formLabelWidth: "120px",
        };
    },
    computed: {
        dialogTitle() {
            return this.dialogType === "changePassword" ? 'Change Password' : this.dialogType === "changeTransactionPassword" ?  'Change Transaction Password' :  this.dialogType === "changeRenewalData" ?  "Change Renewal Date" : '';
        },
    },
    created() {
        /**
         * Including  Of Validation Rule
         */
        this.formRules = {
            newPassword: requiredRule("newPassword"),
        };
    },
    methods: {
        handleCloseDialog() {
            this.$emit("childClose");
        },
        handleDateUpdate(value) {
            this.formData.newRenewalDate = value;
        },
        sendChildFormData() {
            this.loading = true;
            this.$refs.refForm.validate((valid) => {
                if (valid) {
                    // WHEN EDIT MERGE NEW PARAMETERS
                    if (this.dialogType === "edit") {
                        this.formData = Object.assign(this.formData, {
                            dialog_type: this.dialogType,
                        });
                    }
                    this.$emit("getChildFormData", this.formData);
                    this.loading = false;
                }
            });
        },
    },
};
</script>
